import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, Image, Linking, View, StyleSheet, Dimensions } from 'react-native';
import Timeline from 'react-native-timeline-flatlist'
import Constants from 'expo-constants';

const ITEM_WIDTH = Dimensions.get('window').width;


export default function InfoScreen({ route, navigation }) {
 
  const [infos, setInfos] = useState();

  useEffect(() => {
    axios.get(
      'https://medici-club.com/infos/',
    ).then(res =>{
    setInfos(res.data.sort(({id: previousID}, {id: currentID}) => currentID - previousID).map(item=> ({time :item.time.slice(5,10), title: item.title, description: item.description, imageUrl: item.uri, link: item.link})));})
  }, []);

  function renderDetail(rowData, sectionID, rowID) {
    let title = <Text style={[styles.title]}>{rowData.title}</Text>
    var img = null;
    var desc = null;
	  console.log(rowData);
    if(rowData.imageUrl)
      img = (
        <View style={styles.descriptionContainer}>   
        <View style={styles.imageContainer}>   
	  <Image source={{uri: rowData.imageUrl}} style={styles.image}/>
        </View>
        </View>
      )
    if(rowData.description)
      desc = (
        <View style={styles.descriptionContainer}>   
          <Text style={[styles.textDescription]}>{rowData.description}</Text>
        </View>
      )
    
    return (
      <View style={{flex:1}}>
        {title}
        {img}
        {desc}
      </View>
    );
  };

    return (
      <View style={styles.container}>
        <Timeline 
          style={styles.list}
          data={infos}
          circleSize={20}
          circleColor='rgba(0,0,0,0)'
          lineColor='#989898'
          timeContainerStyle={{minWidth:52, marginTop: -5}}
          timeStyle={{textAlign: 'center', backgroundColor:'#A9A9A9', color:'white', padding:5, borderRadius:13}}
          descriptionStyle={{color:'gray'}}
          options={{
            style:{paddingTop:5}
          }}
          innerCircle={'icon'}
          onEventPress={(rowData) => {Linking.openURL(rowData.link)}}
	  renderDetail={renderDetail}
        />
      </View>
    );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    backgroundColor:'white'
  },
  list: {
    flex: 1,
    marginTop:20,
  },
  title:{
    fontSize:20,
    fontWeight: 'bold'
  },
  descriptionContainer:{
    flex: 1,
  },
  imageContainer:{
    paddingVertical: 5,
  },
  image:{
    width: '100%',
    height: ITEM_WIDTH > 800 ? 350 : 150,
    resizeMode: 'cover'
  },
  textDescription: {
    color: 'gray'
  }
});
