import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { View, Text, ScrollView, StyleSheet, Button } from 'react-native';
import { Header, ListItem, Icon } from 'react-native-elements';
import logo from '../assets/package_purchase.png';
import ProgressStamp from '../components/progressStamp';
import AwesomeAlert from 'react-native-awesome-alerts';

import { store } from '../helpers/store.js';
import { userActions } from '../actions/user.actions.js';


  function renderBadge(level) {
    switch(level) {
      case 1:
      return (
                    <View style = {styles.tag}>
        <Icon name='star' type='font-awesome' color='#cd7f32'/>
                   <Text style = {{marginLeft : 10}} >ブロンズ会員</Text>
      </View>);
      case 2:
      return(
                    <View style = {styles.tag}>
        <Icon name='star' type='font-awesome' color='silver'/>
                   <Text style = {{marginLeft : 10}} >シルバー会員</Text>
      </View>);

      case 3:
      return(
                    <View style = {styles.tag}>
        <Icon name='star' type='font-awesome' color='gold'/>
                   <Text style = {{marginLeft : 10}} >ゴールド会員</Text>
      </View>);
      case 4:
      return(
                    <View style = {styles.tag}>
        <Icon name='star' type='font-awesome' color='#33FFFC'/>
                   <Text style = {{marginLeft : 10}} >プラチナ会員</Text>
      </View>);
      case 5:
      return(
                    <View style = {styles.tag}>
        <Icon name='star' type='font-awesome' color='#EDDA74'/>
                   <Text style = {{marginLeft : 10}} >プレミアム会員</Text>
      </View>);

    default:
      return (
                    <View style = {styles.tag}>
                   <Text style = {{marginLeft : 10}} >パール</Text>
      </View>);
  }
}

export default function userScreen({ navigation }) {

const dispatch_redux = useDispatch();

const [userstats, setUserstats] = useState({stamps:[]});
const [withdrawalertVisible, setwithdrawAlertVisible] = useState(false);


 const logout = () => {
    fetch("https://medici-club.com/logout/", {
      credentials: "include",
    })
    .then((data) => {
      window.location.reload(true)
    })
    .catch((err) => {
      console.log(err);
    });
  };

useEffect(() => {
     const queryString = require('query-string');
     const parsed_query = queryString.parse(location.search);
    fetch("https://medici-club.com/whoami/", {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((data) => {
      dispatch_redux(userActions.login({user : {first_name : data.first_name, second_name : data.second_name, pk : data.pk, UID : data.UID, email : data.email, level : data.level, point_balance: data.point_balance, token_balance : data.token_balance,eth_balance : data.eth_balance, usdt_balance : data.usdt_balance, btc_balance: data.btc_balance, total_asset: data.total_asset, yesterday_reward: data.yesterday_reward}}));
    axios.get(
	      'https://medici-club.com/user/' + data.pk + '/',
    ).then(res =>{
      setUserstats(res.data);
    })
    })
    .catch((err) => {
      console.log(err);
    });
    }, []);

    const user = useSelector(state => state.user)
    return (
<>
      <ScrollView style={styles.container}>
	    <Header
	    placement='right'
	    backgroundColor='#989898'
  leftComponent={{ text: 'こんにちは, ' + user.first_name + ' ' + user.second_name + ' 様' , style: { color: '#fff'} }}
  rightComponent={renderBadge(user.level)}

/>
   <ProgressStamp collectedStamps = {userstats.stamps}/>
      <img src={logo} style = {{marginBottom : '3%'}} onClick = {() => navigation.navigate('会員一覧')}/>
      <ListItem  onPress = {() => navigation.navigate('ステーキング', {user, userstats})}
         title={'ステーキング'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => navigation.navigate('ポイント履歴', userstats)}
         title={'所持ポイント'}
         rightTitle= {user.point_balance + 'P'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => navigation.navigate('購入履歴', userstats)}
         title={'購入履歴'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => navigation.navigate('送金履歴', userstats)}
         title={'送金履歴'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => navigation.navigate('アフィリエイト履歴', userstats)}
         title={'アフィリエイト履歴'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => navigation.navigate('情報変更ページ')}
         title={'個人情報設定'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => navigation.navigate('証明書一覧')}
         title={'修了証明書一覧'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => {navigator.clipboard.writeText('https://web.medici-club.com/?ref=' + Buffer.from(user.email).toString('base64')), setwithdrawAlertVisible(true)}}
         title={'招待コードをコピー'}
         chevron = 'true'
      />
      <ListItem  onPress = {() => logout()}
         title={'ログアウト'}
         chevron = 'true'
      />
	</ScrollView>
	
	<AwesomeAlert
          show={withdrawalertVisible}
          showProgress={false} 
	  messageStyle={{fontSize: 12}}
          message="コピーしました"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="了解しました"
          confirmButtonColor="red"
          onConfirmPressed={() => setwithdrawAlertVisible(false)}
        />
	    </>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tag: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

});
