import * as React from 'react';
import { ScrollView, StyleSheet, Dimensions, StatusBar } from 'react-native';
import { TabBar, TabView, SceneMap } from 'react-native-tab-view';
import { ListItem } from 'react-native-elements';


export default function PurchaseHistory({ route, navigation }) {
const renderTabBar = props => (
  <TabBar
    {...props}
    indicatorStyle={{ backgroundColor: 'gold' }}
    style={{ backgroundColor: '#989898' }}
  />
);


const initialLayout = { width: Dimensions.get('window').width };


return (
<ScrollView>
  {
    route.params.purchase_history.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
	 <ListItem
	key={i}
	title={l.item}
	rightTitle={l.date}
      />
    ))
  }
</ScrollView>
);
}

const styles = StyleSheet.create({
  container: {
    marginTop: StatusBar.currentHeight,
  },
  scene: {
    flex: 1,
  },
});

