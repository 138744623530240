import React, { useState, useEffect } from 'react';
import { Text, Platform, StyleSheet, View, ScrollView, Button, Alert, Keyboard, KeyboardAvoidingView, TouchableWithoutFeedback} from "react-native";
import { AwesomeTextInput } from "react-native-awesome-text-input";
import { Input } from 'react-native-elements';
import { useForm, Controller } from "react-hook-form";
import { Ionicons } from '@expo/vector-icons';
import axios from 'axios';
import AwesomeButtonRick from 'react-native-really-awesome-button/src/themes/rick';
import {Icon} from 'react-native-elements';
import Constants from 'expo-constants';

export default function editUserScreen(props) {

  const { register, setValue, handleSubmit, control, errors } = useForm();
  var firstname = React.createRef()
  var postcode = React.createRef()
  var firstline = React.createRef()
  var secondline = React.createRef()
  var thirdline = React.createRef()
  var forthline = React.createRef()
  const onSubmit = async(data) => {
    if(data.secondname == "" || data.firstname == "" || data.postcode == "" || data.firstline == "" || data.secondline == "" || data.thirdline == "" || data.forthline == ""){
      console.log('Please fill all form')
      return
  }
    try {
	const userData = {
	  "username" : data.secondname.concat(data.firstname),
	  "secondname" : data.secondname,
	  "firstname" : data.firstname,
	  "postcode" : data.postcode,
	  "firstline" : data.firstline,
	  "secondline" : data.secondline,
	  "thirdline" :data.thirdline,
	  "forthline" : data.forthline,}
	console.log(userData);
        const api = 'https://medici-club.com/users/';
	axios.post(api, userData,{ headers: {'Content-Type': 'application/json'}}).then(res=>props.onLogin()).catch(err=>console.log(err));
    } catch (E) {
      console.log(E);
    }
  };

  const onChange = arg => {
    return {
      value: arg.nativeEvent.text,
    };
  };
  return (
      <ScrollView>
      <View style={styles.container}>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             label="姓" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => firstname.focus()}
            />
          )}
          name="secondname"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {firstname = ref;}}
             label="名" 
             onBlur={onBlur}
             returnKeyType="next"
             onChangeText={value => onChange(value)}
             value={value}
             onSubmitEditing={() => postcode.focus()}
            />
          )}
          name="firstname"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {postcode = ref;}}
             label="郵便番号" 
             keyboardType='numeric'
	     onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => firstline.focus()}
            />
          )}
          name="postcode"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {firstline = ref;}}
             label="都道府県" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => secondline.focus()}
            />
          )}
          name="firstline"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {secondline = ref;}}
             label="市区町村" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => thirdline.focus()}
            />
          )}
          name="secondline"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {thirdline = ref;}}
             label="番地" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => forthline.focus()}
            />
          )}
          name="thirdline"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {forthline = ref;}}
             label="建物名" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
            />
          )}
          name="forthline"
        />
        </View>
      <View style={styles.button}>
        <AwesomeButtonRick width={250} textSize={23} onPress={handleSubmit(onSubmit)}type="primary">個人情報変更</AwesomeButtonRick>
      </View>
      </View>
      </ScrollView>
   );
};

const styles = StyleSheet.create({
  label: {
    margin: 20,
    fontSize: 20,
    fontWeight: 'normal',
    marginLeft: 0,
  },
  title: {
    marginTop: 30,
    fontSize: 30,
    color: 'gray',
    fontWeight: 'bold',
  },
  button: {
    marginTop: 40,
    height: 40,
    borderRadius: 4,
  },
  container: {
    alignItems: 'center',
    margin: 30,
  },
   inputContainer: {
    marginTop: 20,
    alignSelf: 'stretch',
   },
});

