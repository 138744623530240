import "react-step-progress-bar/styles.css";
import React from 'react';
import {StyleSheet, View, Text, Image, Dimensions} from 'react-native';
import { ProgressBar,Step } from "react-step-progress-bar";
import { Icon } from 'react-native-elements'

const ITEM_WIDTH = Dimensions.get('window').width;

export default function ProgressStamp(props) {

const collected = props.collectedStamps;
const unCollected = new Array(10 - collected.length).fill(null);
const stamps = collected.concat(unCollected);
return(

  <View style = {styles.container}>
	<Text style = {styles.progressbarText}> ポイントスタンプ</Text>
  <View style = {styles.progressbar }>
	{stamps.slice(0,5).map((stamp) => stamp ? <Image source={stamp} style={styles.image}/> : <Image source={'https://chainupjptests.com/media/images/defaults/emptystamp.jpg'} style={styles.image}/>)}
  </View>
  <View style = {styles.progressbar }>
	{stamps.slice(5,10).map((stamp) => stamp ? <Image source={stamp} style={styles.image}/> : <Image source={'https://chainupjptests.com/media/images/defaults/emptystamp.jpg'} style={styles.image}/>)}
  </View>
  </View>
);

}
const styles = StyleSheet.create({
	progressbar:{
         flex: 1,
	 flexDirection: 'row',
	 padding : '2%',
         justifyContent: 'space-between',
	},
	image:{
	  width: ITEM_WIDTH > 800 ? 800 / 7 : ITEM_WIDTH / 7,
	  height: ITEM_WIDTH > 800 ? 800 / 7 : ITEM_WIDTH / 7,
	},
	container:{
	  flex: 1,	
	  margin : '3%',
	  padding : '2%',
	  backgroundColor: 'white',
	},
	progressbarText:{
          padding : '2%',
	},
  indexedStep: {
    color: 'white',
    width: '40px',
    height: '40px',
    fontSize: '12px',
    backgroundColor: 'rgba(0, 116, 217, 1)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
},
});
