import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Image, View, ScrollView, Linking, TextInput, StyleSheet, Dimensions } from 'react-native';
import Modal from "modal-enhanced-react-native-web";
import { Icon , ListItem, Button } from 'react-native-elements';
import { userActions } from '../actions/user.actions.js';
import AwesomeAlert from 'react-native-awesome-alerts';
import axios from 'axios';
import { store } from '../helpers/store.js';



const ITEM_WIDTH = Dimensions.get('window').width;


export default function MembershipDetailsScreen({ route, navigation }) {
  
  const good = route.params
  const dispatch_redux = useDispatch();
  const user = useSelector(state => state.user)
  const [overlayVisible, setOverlayVisible] = useState(false);
  const queryString = require('query-string');
  const parsed_query = queryString.parse(location.search);
  const [showOverlay, setShowOverlay] = useState(parsed_query.purchased_anything == 'False');

  
  function handlePurchase() {
  const api = 'https://medici-club.com/membershippurchase';

    if (showOverlay) { 
      setOverlayVisible(true);
    }else{
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'id' : good.id}}).
      then(res => {
	Linking.openURL(res.data)
      }).catch(function (error) {
        console.log(error);
      });
   }
  }

  function handleCloseModal() {
    setOverlayVisible(false);
    setShowOverlay(false);
  }

  return (
    <ScrollView>
    <View style={styles.container}>
      <Image
        source = {good.uri}
        style = {styles.imageStyle}
        resizeMode = 'contain'
      />
    
    <View>
      <Text style={styles.title}>{good.title}</Text>
    </View>
    <View style={styles.subTitle}>
	  <Text style={styles.price}> {good.price}USDT</Text>
    </View>
    <View style={styles.buttonWrapper}> 
      <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} onPress = {() => handlePurchase()} title='会員を購入する'/>
	  </View>
     {good.stamps.length > 0 && <View style={styles.bonusWrapper}>
          <Text>還元スタンプ:</Text>
           {good.stamps.map(stamp => <Image source={stamp} style={styles.stamp}/> )}
          </View>}
     {good.bonuspoints > 0 && <View style={styles.bonusWrapper}>
           <Text>還元ポイント: {good.bonuspoints}P</Text>
          </View>}

    <View style={styles.aboutText}>
      <Text>{good.description}</Text>
    </View>
    <Modal isVisible = {overlayVisible} style={styles.bottomModal}>
      <ScrollView>	  
      <View style={styles.modalContent}>	  
	    <Text style = {styles.tutorialText}>支払いパスワードの設定方法</Text>
	    <Image style = {styles.tutorial} source={'https://medici-club.com/media/images/defaults/step1.jpg'}/>
	    <Text style = {styles.tutorialText}>ホームページから設定画面へ移動します。</Text>
	    <Image style = {styles.tutorial} source={'https://medici-club.com/media/images/defaults/step2.jpg'}/>
	    <Text style = {styles.tutorialText}>「パスワードを設定」をクリックします。</Text>
	    <Image style = {styles.tutorial} source={'https://medici-club.com/media/images/defaults/step3.jpg'}/>
	    <Text style = {styles.tutorialText}>「送信」ボタンをクリックして認証コードを受け取ります。</Text>
	    <Button buttonStyle = {styles.mainButton} titleStyle = {styles.mainButtonText} onPress = {() => handleCloseModal()} title='閉じる'/>
    </View>
      </ScrollView>	  
    </Modal>
    </View>
	  </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',

  },
  tutorial: {
    width: ITEM_WIDTH / 1.2,
    height: ITEM_WIDTH / 0.554464,
  },
  tutorialText: {
    fontSize: 20,
    marginVertical: 20,
  },
    bonusWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: '5%',
    paddingTop: '2%'
  },
    subTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: '5%',
    paddingVertical: '1%'
    
  },
    title: {
     marginTop : 10,
     fontWeight: "bold",
     fontSize : 25,
     paddingLeft: '5%',
     paddingRight: '5%'
   },
    aboutText: {
    padding: 20, 
  }, 
    paymentTitleText : {
     fontWeight: "bold",
     fontSize: 20,
  },
    imageStyle: {
    width: ITEM_WIDTH > 800 ? 800 : ITEM_WIDTH,
    height: ITEM_WIDTH > 800 ? 400 : ITEM_WIDTH / 2,
  },
    paragraph: {
      margin: 24,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
  },
    mainButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
    modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)"
  },
  mainButton: {
    margin: 5,
    height: 40,
    width: ITEM_WIDTH > 800 ? 800 / 1.2 : ITEM_WIDTH / 1.2,
    borderRadius: 5,
    backgroundColor: 'red'
  },
   tagText: {
     fontSize: 15,
  },
   price:{
     color: 'red',
     fontSize: 23,
   },
   stamp:{
     height: 25,
     width: 25,
     marginLeft: 7,
   },
  buttonWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    fontColor: 'gray',
  },
   modalView: {
    justifyContent: "center",
  },
  paymentTitle: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    },
  paymentListItem: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  textInput: {
    borderWidth: 1,
  },
});

