import * as React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import logo from '../assets/logo_transparent.png';


export default function RedirectScreen() {
  return (
    <View style={styles.container}>
      <img src={logo} style={{width : "50%", padding : 12}}/>
      <Text style={styles.paragraph}>仮登録が完了いたしました。アプリを再度開いてください。</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ecf0f1',
    padding: 8,
  },
  paragraph: {
    margin: 6,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description: {
    margin: 6,
    fontSize: 15,
    textAlign: 'center',
  },
  
});

