import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, View, StyleSheet, FlatList, Dimensions, TouchableOpacity } from 'react-native';
import Constants from 'expo-constants';
import { Button, Card } from 'react-native-elements';
import LevelText from '../components/levelText';
import Image from 'react-native-scalable-image';


const ITEM_WIDTH = Dimensions.get('window').width;

export default function LetureScreen({ route, navigation }) {

  const [lectures, setLectures] = useState();

  useEffect(() => {
    axios.get(
      'https://medici-club.com/lectures/',
    ).then(res =>{
    setLectures(res.data.sort(({id: previousID}, {id: currentID}) => currentID - previousID));})
  }, []);


    return (
      <View style={styles.container}>
        <FlatList 
        data={lectures}
        keyExtractor={(item, title) => title.toString()}
        numColumns={1}

        renderItem={({item}) => (
          <View>
            <Card>
              <TouchableOpacity onPress = {() => navigation.navigate('授業内容', item)}>
              <View style={{flexDirection: 'column'}}>
              <View style={{flex: 1, alignItems: 'center'}}>
                <Image
                  source={{uri : item.uri}}
    		  width= {ITEM_WIDTH > 800 ? 800 / 1.2 : ITEM_WIDTH / 1.2}
                />
                </View>
                <Text style={styles.titleStyle}>{item.title}</Text>
                       <LevelText style= {styles.detailsStyle} level={item.level}/>
		<Text style={styles.authorStyle}>{item.instructor}</Text>
              </View>
             </TouchableOpacity>
        </Card>

          </View>
        )}
        />
      </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: Constants.statusBarHeight,
    backgroundColor: '#ecf0f1',
  },
  imageStyle: {
    width:  ITEM_WIDTH > 800 ? 800 / 1.2 : ITEM_WIDTH / 1.2,
    height: ITEM_WIDTH > 800 ? 800 / 1.2 / 16 * 9 : ITEM_WIDTH / 1.2 / 16 * 9,
    resizeMode: 'cover',
  },
   titleStyle: {
     marginTop : '10px',
     fontSize : 18,
     fontWeight: "bold",
   },
    authorStyle: {
     marginTop : 2,
     fontSize : 13,
     textAlign: 'left',
     color: 'gray',
   },
   detailsStyle: {
     marginTop : 2,
     textAlign: 'left',
     fontSize : 17,
   }
});
