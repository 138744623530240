import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Modal from "modal-enhanced-react-native-web";
import { View, Text, TextInput, ScrollView, Dimensions, StyleSheet } from 'react-native';
import { TabBar, TabView, SceneMap } from 'react-native-tab-view';
import { Header, ListItem, Icon, Button } from 'react-native-elements';
import AwesomeAlert from 'react-native-awesome-alerts';
import qr from '../assets/qr.png';

export default function stakingScreen({ route, navigation }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);
  const [withdrawalertVisible, setwithdrawAlertVisible] = useState(false);
  const [withdrawCurrency, setWithdrawCurrency] = useState('MTAR');
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [withdrawAlertText, setWithdrawAlertText] = useState('');
  const [csrf, setcsrf] = useState('');
  const ITEM_WIDTH = Dimensions.get('window').width;

const renderTabBar = props => (
  <TabBar
    {...props}
    indicatorStyle={{ backgroundColor: '#4666FF', height: '3px' }}
    style={{ backgroundColor: '#989898' }}
  />
);


const assets = [{amount: route.params.user.token_balance, title: "MTAR"},{amount: route.params.user.usdt_balance, title: "USDT"},{amount: route.params.user.eth_balance, title: "ETH"},{amount: route.params.user.btc_balance, title: "BTC"}]
const purchase_items = [{title: 'お金の教育', monthlyGain: 30, price: 2000}]

  const getCSRF = () => {
    fetch("https://medici-club.com/csrf/", {
      credentials: "include",
    })
    .then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      //save token to storage 
      setcsrf(csrfToken);
    })
    .catch((err) => {
      console.log(err);
    });
  }

const sendTransfer = () => {
	if (withdrawAmount <= 0) {
	setModalVisible(false);
	setWithdrawAlertText("0より大きい金額を入力してください。");
	setwithdrawAlertVisible(true);
	return;
	}
    fetch("https://medici-club.com/transfer/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
	"X-CSRFToken": csrf,
      },
      credentials: "include",
      body: JSON.stringify({sender: route.params.user.email, receiver: withdrawAddress, amount: withdrawAmount, coin: withdrawCurrency}),
    })
    .then((response) => {
	setModalVisible(false);
	if (response.status == 200) {
	setWithdrawAlertText("送金が完了しました。");
	setwithdrawAlertVisible(true);
	}
	if (response.status == 403) {
	setWithdrawAlertText("ユーザーが不正です。");
	setwithdrawAlertVisible(true);
	}
	if (response.status == 400) {
	setWithdrawAlertText("残高が足りません。");
	setwithdrawAlertVisible(true);
	}
    })
}
const sendRequest = (title) => {
    if ( route.params.user.eth_balance < 0.003 && ! title.includes("BTC") && title != 'deposit') {
	setModalVisible(false);
	setWithdrawAlertText("ETH残高が最低送金手数料より少ないため、送金できません。");
	setwithdrawAlertVisible(true);
    } else {
    fetch("https://medici-club.com/addorder/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
	"X-CSRFToken": csrf,
      },
      credentials: "include",
      body: JSON.stringify({title: title}),
    })
    .then(() => {
	setModalVisible(false);
	setWithdrawAlertText("引き出し申請が完了しました。");
	setwithdrawAlertVisible(true);
    })
    }
}



const FirstRoute = () => (
<ScrollView>
  {
    assets.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
      <ListItem
	key={i}
	title={l.amount}
	rightTitle={l.title}
	onPress = {() => {setModalVisible(true); setWithdrawCurrency(l.title)}}
        chevron = {l.amount != 0 ? true : false}
      />
    ))
  }
</ScrollView>
);


const ThirdRoute = () => (
<ScrollView>
  {
    route.params.userstats.reward_history.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
      <ListItem
	key={i}
        title={l.title}
	subtitle={l.amount + 'MTAR'}
	rightTitle={l.date}
      />
    ))
  }
</ScrollView>
);

const purchaseRoute = () => (

<ScrollView>
  {
    route.params.userstats.staking_items.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
      <ListItem
	key={i}
        title={l.title}
	subtitle={l.description}
	rightTitle={l.deposit}
      />
    ))
  }


</ScrollView>

);

const initialLayout = { width: Dimensions.get('window').width };

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'first', title: '収益中' },
    { key: 'second', title: '資産一覧' },
    { key: 'third', title: '収益履歴' },
  ]);

  const renderScene = SceneMap({
    second: FirstRoute,
    first: purchaseRoute,
    third: ThirdRoute,
  });

useEffect(() => {
	getCSRF();
    }, []);
    return (
      <View style={styles.container}>

  <View style = {styles.bannercontainer}>
  <View style = {styles.progressbar }>
	<Text style = {styles.progressbarTitle}> あなたの総資産: {route.params.user.total_asset} USDT</Text>
  </View>
  <View style = {styles.progressbar }>
	<Text style = {styles.progressbarSubtitle}> 昨日の収益: {route.params.user.yesterday_reward} USDT</Text>
  </View>
  <View style = {styles.buttonbar }>
	<Button titleStyle={{ color: 'white'}} type='clear' onPress={() => setAlertVisible(true)} title={'入金'}/>
	<View style={styles.verticleLine}></View>
	<Button titleStyle={{ color: 'white'}} type='clear' onPress={() => setIndex(1)} title={'送金・引き出し'}/>
  </View>

  </View>

	<TabView
          navigationState={{ index, routes }}
          renderTabBar={renderTabBar}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={initialLayout}
          style={styles.container}
        />
	    	<Modal
          isVisible = {modalVisible}
          style={styles.bottomModal}	  
        >
	      <View style={styles.modalContent}>
	<View style={styles.paymentListItem}>
      <Text style={styles.paymentTitleText}>{'外部アドレスを入れて' + withdrawCurrency + ' を引き出し,もしくはアプリ内メールを入れてユーザーへ送金(外部へ引き出しするときは手数料が別途かかります)。'}</Text>
      <Icon
        name='close'
        type='evillcons'
	  onPress = {() => setModalVisible(false)}/>
        </View> 
        <View style={styles.paymentListItem}> 
	<Text>{'外部アドレス/アプリ内メール:' }</Text>
	<TextInput
	  style = {styles.textInput}
	  keyboardType = 'text'
	  onChangeText={text => setWithdrawAddress(text)}
          value={withdrawAddress}
        />
        </View>
    <View style={styles.paymentListItem}>
	<Text>{'金額:' }</Text>
	<TextInput
	  style = {styles.textInput}
	  keyboardType = 'numeric'
	  onChangeText={text => setWithdrawAmount(text)}
          value={withdrawAmount}
        />
    </View>
    <View style={styles.paymentListItem}>
          <Button titleStyle={{color : "#4666FF"}} type="clear" onPress = {() => {withdrawAddress.includes('@') ? sendTransfer() : sendRequest(withdrawAddress + ' ' + withdrawAmount + withdrawCurrency)}} title={withdrawAddress.includes('@') ? 'ユーザーへ送金'　: '外部へ引き出し申請'}/>
    </View>
    </View>
	  </Modal>
	<AwesomeAlert
          show={alertVisible}
          showProgress={false} 
          title="入金"
	  customView={<img style = {{width: "50%", margin: 10 }} src={qr} />}
	  messageStyle={{fontSize: 12}}
          message="0x08Fb522284a58eDb32205095d7640fC44c24372C ETH/USDT/MTARのみ入金してください"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="キャンセル"
          confirmText="送金しました"
          confirmButtonColor="red"
          onCancelPressed={() => setAlertVisible(false)}
          onConfirmPressed={() => {setAlertVisible(false); sendRequest('deposit')}}
        />
	<AwesomeAlert
          show={withdrawalertVisible}
          showProgress={false} 
          title="引き出し申請"
	  messageStyle={{fontSize: 12}}
          message={withdrawAlertText}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="了解しました"
          confirmButtonColor="red"
          onConfirmPressed={() => setwithdrawAlertVisible(false)}
        />

      </View>
	
    );
}

const styles = StyleSheet.create({
    bottomModal: {
    justifyContent: "flex-end",
    margin: 0
  },
    progressbar:{
         flex: 1,
	 flexDirection: 'row',
	 padding : '2%',
         justifyContent: 'space-between',
	},
	buttonbar:{
         flex: 1,
	 flexDirection: 'row',
	 alignItems: 'center',
	 padding : '2%',
         width: '100%',
         justifyContent: 'space-evenly',
	},
	bannercontainer:{
	  height : '20%',
	  margin : '3%',
	  padding : '2%',
	  backgroundColor: '#4666FF',
	  borderRadius: '20px',
	},
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)"
  },
  container: {
    flex: 1,
  },
	progressbarTitle:{
          fontSize: '20px',
          color: 'white',
	},
	progressbarSubtitle:{
          fontSize: '14px',
          color: 'white',
	},
  paymentTitleText : {
     fontWeight: "bold",
     fontSize: 14,
  },
  paymentListItem: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginBottom: 13,
    justifyContent: 'space-between',
    alignItems: "center",
  },
  tag: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  qr: {
    width: '50%',
  },
  textInput: {
    borderWidth: 1,
  },
});
