import { userConstants } from '../constants/user.constants.js';

export function users(state = {user : {}}, action) {
    switch (action.type) {
	case userConstants.LOGIN:
	    return {
		...action.payload.user,
	    };
	case userConstants.CSRF:
	    return {
		...state,
		user : {...state.user, csrf : action.payload.token}
	    };
	case userConstants.UPDATE_TOKEN:
	    return {
		...state,
		user : {...state.user, token_balance : action.payload.token_balance}
	    };
	case userConstants.UPDATE_POINT:
	    return {
		...state,
		user : {...state.user, point_balance : action.payload.point_balance}
	    };
	case userConstants.UPDATE_STAMP:
	    return {
		...action.payload.stamp,
	    };
	default:
	    return state
        }
}
