import React, { useEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';

import TabBarIcon from '../components/TabBarIcon';
import InfoScreen from '../screens/InfoScreen';
import PurchaseScreen from '../screens/PurchaseScreen';
import MembershipScreen from '../screens/MembershipScreen';
import MembershipDetailsScreen from '../screens/MembershipDetailsScreen';
import PurchaseDetailsScreen from '../screens/PurchaseDetailsScreen';
import PurchaseHistoryScreen from '../screens/PurchaseHistoryScreen';
import TransferHistoryScreen from '../screens/TransferHistoryScreen';
import AffiliateHistoryScreen from '../screens/AffiliateHistoryScreen';
import LectureScreen from '../screens/LectureScreen';
import LectureDetailsScreen from '../screens/LectureDetailsScreen';
import UserScreen from '../screens/UserScreen';
import PointDetailsScreen from '../screens/PointDetailsScreen';
import StakingScreen from '../screens/StakingScreen';
import editUserScreen from '../screens/editUserScreen';
import AlumniScreen from '../screens/AlumniScreen';
import AlumniDetailsScreen from '../screens/AlumniDetailsScreen';
import CertificateScreen from '../screens/CertificateScreen';
import { store } from '../helpers/store.js';
import { userActions } from '../actions/user.actions.js';


const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'User';
const Stack = createStackNavigator();

function InfoScreenStack() {
 return( 
 <Stack.Navigator>
 <Stack.Screen name="お知らせ" component={InfoScreen}                 
         options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />

</Stack.Navigator>
 )
}

function PurchaseScreenStack() {
 return( 
 <Stack.Navigator>
 <Stack.Screen name="購入一覧" component={PurchaseScreen}                 
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 <Stack.Screen name="購入内容" component={PurchaseDetailsScreen} 
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 </Stack.Navigator>
 )
}

function LectureScreenStack() {
 return( 
 <Stack.Navigator>
 <Stack.Screen name="授業一覧" component={LectureScreen} 
         options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 <Stack.Screen name="授業内容" component={LectureDetailsScreen}          
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }}/>
 </Stack.Navigator>
 )
}

function AlumniScreenStack() {
 return( 
 <Stack.Navigator>
 <Stack.Screen name="鑑定士一覧" component={AlumniScreen}
         options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }}/>
 <Stack.Screen name="鑑定士" component={AlumniDetailsScreen}
	          options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }}/>
 <Stack.Screen name="購入内容" component={PurchaseDetailsScreen} 
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 </Stack.Navigator>
 )
}

function UserScreenStack() {
 return( 
 <Stack.Navigator>
 <Stack.Screen name="マイページ" options={{headerShown: false,}} component={UserScreen}/ >
 <Stack.Screen name="ステーキング"          
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} 
	 component={StakingScreen} />
 <Stack.Screen name="ポイント履歴"          
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} 
	 component={PointDetailsScreen} />

	 <Stack.Screen name="アフィリエイト履歴"
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} 
	        component={AffiliateHistoryScreen} />
	 <Stack.Screen name="送金履歴"
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} 
	        component={TransferHistoryScreen} />
	 <Stack.Screen name="購入履歴"
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} 
	        component={PurchaseHistoryScreen} />
 <Stack.Screen name="会員一覧"  component={MembershipScreen}
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 <Stack.Screen name="会員情報"  component={MembershipDetailsScreen}
	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 
 <Stack.Screen name="情報変更ページ"  component={editUserScreen}
 	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 <Stack.Screen name="証明書一覧" component={CertificateScreen}
 	 options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackTitleVisible: false,
                }} />
 </Stack.Navigator>
 )
}



export default function BottomTabNavigator({navigation}) {

  navigation.setOptions({ headerShown: false });

  const dispatch_redux = useDispatch();

  useEffect(() => {
     const queryString = require('query-string');
     const parsed_query = queryString.parse(location.search);
     dispatch_redux(userActions.login({user : {first_name : parsed_query.first_name, second_name : parsed_query.second_name, pk : parsed_query.pk, level : parseInt(parsed_query.level), point_balance: parsed_query.point_balance, token_balance : parsed_query.token_balance}}));
     //dispatch_redux(userActions.login({user : {first_name : 'shikyou', second_name : 'kin', pk : 4, UID : '123', level : 5, access_token : 'acc', point_balance: 1000, token_balance : 1000}}));

  }, []);

  return (
    <BottomTab.Navigator initialRouteName={INITIAL_ROUTE_NAME} headerMode= {'none'} tabBarOptions={{activeTintColor: 'black',}}>
      <BottomTab.Screen
        name="User"
        component={UserScreenStack}
        options={{
          title: 'マイページ',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-person" />,
        }}
      />
      <BottomTab.Screen
        name="info"
        component={InfoScreenStack}
        options={{
          title: 'お知らせ',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-notifications" />,
        }}
      />
      <BottomTab.Screen
        name="Lecture"
        component={LectureScreenStack}
        options={{
          title: '授業一覧',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-book" />,
        }}
      />
      <BottomTab.Screen
        name="Purchase"
        component={PurchaseScreenStack}
        options={{
          title: '購入一覧',
          tabBarIcon: ({color, focused}) => <TabBarIcon color={color} focused={focused} name="md-basket" />,
        }}
      />
      <BottomTab.Screen
        name="Alumni"
        component={AlumniScreenStack}
        options={{
          title: '鑑定士一覧',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-people" />,
        }}
      />
    </BottomTab.Navigator>
  );
}
