import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Image, View, ScrollView, Linking, TextInput, StyleSheet, Dimensions } from 'react-native';
import { Button } from 'react-native-elements';
import Modal from "modal-enhanced-react-native-web";
import { Icon } from 'react-native-elements';
import LevelText from '../components/levelText';
import { userActions } from '../actions/user.actions.js';
import axios from 'axios';
import AwesomeAlert from 'react-native-awesome-alerts';
import { store } from '../helpers/store.js';



const ITEM_WIDTH = Dimensions.get('window').width;

export default function LectureDetailsScreen({ route, navigation }) {
  
  const lecture = route.params
  const dispatch_redux = useDispatch();
  const user = useSelector(state => state.user)
  const [modalVisible, setModalVisible] = useState(false);
  const [pointmodalVisible, setPointModalVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [amount, setAmount] = useState(1);
  function handlePurchase(UID, access_token) {
    const queryString = require('query-string');
    const parsed_query = queryString.parse(location.search);
    const api = 'https://medici-club.com/subscribe/';
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'id' : lecture.id}}).
      then(res => {
        setModalVisible(false);
        setAlertVisible(true);
        dispatch_redux(userActions.updatePoint(res.data))
        handleSendEmail();
      }).catch(function (error) {
        console.log(error);
      });
  }

  function handleSendEmail(UID, access_token) {
    const queryString = require('query-string');
    const parsed_query = queryString.parse(location.search);
    const api = 'https://medici-club.com/subscribesendemail/';
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'id' : lecture.id}}).catch(function (error) {
        console.log(error);
      });
  }

  function handlePointPurchase(UID, access_token) {
    const queryString = require('query-string');
    const parsed_query = queryString.parse(location.search);
    const api = 'https://medici-club.com/pointpurchase';
    if(amount != 0){
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'amount' : amount}}).
      then(res => {
	if (res.status == 200) {
	setPointModalVisible(false);
	dispatch_redux(userActions.updatePoint(+user.point_balance + +amount))
	dispatch_redux(userActions.updateToken(res.data))
      }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  function handleLink() {
    Linking.openURL(lecture.link);
    setAlertVisible(true);
  }

  function switchText(l) {
    switch(l) {
      case 0:
      return ('初級クラス');
      case 1:
      return ('中級クラス');
      case 2:
      return ('上級クラス');
    default:
      return ;
  }
}

  return (
	  <ScrollView>
    <View style={styles.container}>
      <Image
        source={lecture.uri}
        style={styles.imageStyle}
      />
    
    <View>
      <Text style={styles.title}>{lecture.title}</Text>
      <Text style={styles.level}>{switchText(lecture.lectureLevel)}</Text>
    </View>
    <View style={styles.subTitle}>
          <LevelText style= {styles.tagText} level={lecture.level}/>
	  <Text style={styles.price}>{lecture.price}P({lecture.price_usdt}USDT相当)</Text>
    </View>
    <View style={styles.subTitle}>
	   <LevelText style= {styles.tagText} level={0}/>
	  <Text style={styles.price}>残り{lecture.remaininginventory}/{lecture.inventory}点</Text>
    </View>
    <View style={styles.buttonWrapper}> 
     {user.level >= lecture.level && lecture.remaininginventory > 0 && (lecture.link != "" ? <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} disabled = {lecture.remaininginventory <= 0} onPress = {() => handleLink()} title='日付を選択する'/> : <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} onPress = {() => setModalVisible(true)} title='このレクチャーを受講'/>)}
     {lecture.remaininginventory <= 0 && <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} disabled = {true} onPress = {() => setModalVisible(true)} title='現在在庫切れです。'/>}

      {user.level < lecture.level && lecture.remaininginventory > 0 && <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} disabled = {true} onPress = {() => setModalVisible(true)} title='会員ランクを満たしていません'/>}
	  </View>
        {lecture.stamps.length > 0 && <View style={styles.bonusWrapper}>
          <Text>還元スタンプ:</Text>
           {lecture.stamps.map(stamp => <Image source={stamp} style={styles.stamp}/> )}
          </View>}
     {lecture.bonuspoints > 0 && <View style={styles.bonusWrapper}>
           <Text>還元ポイント: {lecture.bonuspoints}</Text>
          </View>}

    <View style={styles.aboutText}>
      <Text>{lecture.description}</Text>
    </View>
    </View>
    <Modal
          isVisible = {modalVisible}
          style={styles.bottomModal}
        >
              <View style={styles.modalContent}>
        <View style={styles.paymentListItem}>
      <Text style={styles.paymentTitleText}>{lecture.title}</Text>
      <Icon
        name='close'
        type='evillcons'
          onPress = {() => setModalVisible(false)}/>
        </View>
        <View style={styles.paymentListItem}>
        <Text>{'所持ポイント:'}</Text>
        <Text>{user.point_balance + 'P'}</Text>
    </View>
        <View style={styles.paymentListItem}>
          {user.point_balance >= lecture.price && <Button buttonStyle={{backgroundColor : "red"}} onPress = {() => handlePurchase()} color="red" title={'ポイントで支払う'}/>}
          {user.point_balance < lecture.price && <Button buttonStyle={{backgroundColor : "red"}} disabled = {true} color="red" title={'ポイント不足'}/>}
          <Button titleStyle={{color : "red"}} color="red" type="clear" onPress = {() => setPointModalVisible(true)} title={'ポイントを購入'}/>
    </View>
    </View>

          </Modal>
        <Modal
          isVisible = {pointmodalVisible}
          style={styles.bottomModal}
        >
              <View style={styles.modalContent}>
        <View style={styles.paymentListItem}>
      <Text style={styles.paymentTitleText}>{'ポイントを購入する'}</Text>
      <Icon
        name='close'
        type='evillcons'
          onPress = {() => setPointModalVisible(false)}/>
        </View>
        <View style={styles.paymentListItem}>
        <Text>{'所持ポイント:'}</Text>
        <Text>{user.point_balance + 'P'}</Text>
        </View>
        <View style={styles.paymentListItem}>
        <Text>{'購入ポイント:' }</Text>
        <TextInput
          style = {styles.textInput}
          keyboardType = 'numeric'
          onChangeText={text => setAmount(text)}
          value={amount}
      />
    </View>
        <View style={styles.paymentListItem}>
          <Button buttonStyle={{backgroundColor : "red", minWidth : 150 }} title={'購入に進む'} onPress = {() => handlePointPurchase()} />
    </View>
    </View>
          </Modal>
        <AwesomeAlert
          show={alertVisible}
          showProgress={false}
          title="お知らせ"
          message={lecture.link == '' ? "登録が完了しました" : "メールを送信しました"}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          confirmText="閉じる"
          confirmButtonColor="red"
          onConfirmPressed={() => setAlertVisible(false)}
        />
          </ScrollView>


  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
    subTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
       paddingHorizontal: '5%',
    paddingVertical: '1%'
  },
    stamp:{
     height: 25,
     width: 25,
     marginLeft: 7,
   },
    bonusWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: '5%',
    paddingTop: '2%'
  },
    title: {
     marginTop : 10,
     fontWeight: "bold",
     fontSize : 25,
     paddingLeft: '5%',
     paddingRight: '5%'
   },
    buttonWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 20,
    fontColor: 'gray',
  },
    level: {
     marginTop : 5,
     fontSize : 18,
     paddingLeft: '5%',
     paddingRight: '5%'
   },

    aboutText: {
    padding: 20, 
  },
    imageStyle: {
    width: ITEM_WIDTH > 800 ? 800 : ITEM_WIDTH,
    height: ITEM_WIDTH > 800 ? 400 : ITEM_WIDTH / 2,
    resizeMode: 'cover',
  },
    paragraph: {
      margin: 24,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
  },
    mainButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  mainButton: {
    margin: 5,
    height: 40,
    width: ITEM_WIDTH > 800 ? 800 / 1.2 : ITEM_WIDTH / 1.2,
    borderRadius: 5,
    backgroundColor: 'red'
  },
   tagText: {
     fontSize: 15,
  },
   price:{
     color: 'red',
     fontSize: 23,
   },
  buttonWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 20,
    fontColor: 'gray',
  },
    bottomModal: {
    justifyContent: "flex-end",
    margin: 0
  },
    paymentTitleText : {
     fontWeight: "bold",
     fontSize: 20,
  },
    modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)"
  },
  paymentTitle: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    },
  paymentListItem: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  textInput: {
    borderWidth: 1,
  },

});

