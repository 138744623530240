import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, View, StyleSheet, FlatList, Dimensions, TouchableOpacity } from 'react-native';
import Constants from 'expo-constants';
import { Button, Card } from 'react-native-elements';
import Image from 'react-native-scalable-image';

const ITEM_WIDTH = Dimensions.get('window').width;

export default function  AlumniScreen({ route, navigation }) {


  const [alumnis, setAlumnis] = useState();

  useEffect(() => {
    axios.get(
      'https://medici-club.com/alumnis/',
    ).then(res =>{
            console.log(res.data);
    setAlumnis(res.data.sort(({id: previousID}, {id: currentID}) => currentID - previousID));})
  }, []);


    return (
      <View style={styles.container}>
        <FlatList 
        data={alumnis}
        keyExtractor={(item, title) => title.toString()}
        numColumns={1}

        renderItem={({item}) => (
          <View>
            <Card>
              <TouchableOpacity onPress = {() => navigation.navigate('鑑定士', item)}>
              <View style={{flexDirection: 'column', alignItems: 'center'}}>
                <Image
                  source={{uri:item.uri}}
                  width={ITEM_WIDTH > 800 ? 400 : ITEM_WIDTH / 2}
                />
                <Text style={styles.nameStyle}>{item.name}</Text>
                <Text style={styles.locationStyle}>{item.location}</Text>
              </View>
             </TouchableOpacity>
        </Card>

          </View>
        )}
        />
      </View>
    );
  }

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: Constants.statusBarHeight,
    backgroundColor: '#ecf0f1',
  },
  imageStyle: {
    width: '70%',
    resizeMode: 'cover',
  },
   nameStyle: {
     marginTop : '10px',
     fontSize : '25px',
   },
   locationStyle: {
     marginTop : '5px',
     fontSize : '20px',
     color: 'gray',
   }
});
