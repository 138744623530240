import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Platform, ActivityIndicator, Linking, Dimensions, StatusBar, StyleSheet, View, TouchableOpacity} from 'react-native';
import { SplashScreen } from 'expo';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { userActions } from './actions/user.actions.js';

import BottomTabNavigator from './navigation/BottomTabNavigator';
import SignUpScreen from './screens/SignUpScreen';
import PurchaseScreen from './screens/PurchaseScreen';
import LectureScreen from './screens/LectureScreen';
import UserScreen from './screens/UserScreen';
import RedirectScreen from './screens/RedirectScreen';
import SignUpErrorScreen from './screens/SignUpErrorScreen';

import { store } from './helpers/store.js';

const Stack = createStackNavigator();
const ITEM_WIDTH = Dimensions.get('window').width;

export default function App({ navigation }) {
  const [userIsLoggedIn] = React.useState(false);
  const AuthContext = React.createContext();
  console.log(ITEM_WIDTH);
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'SIGN_IN':
          return {
            ...prevState,
            isLoggedIn: 0,
          };
        case 'GO_TO_HOME':
          return {
            ...prevState,
            isLoggedIn: 1,
          };
        case 'SHOW_SIGNUPERROR':
          return {
            ...prevState,
            isLoggedIn: 2,
          };
         case 'SHOW_REDIRECT':
          return {
            ...prevState,
            isLoggedIn: undefined,
          };
      }
    },
    {
	    isLoggedIn: 0,
    }
  );


   useEffect(() => {
   }, []);



    return (
      <Provider store={store}>
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default"/>}
        <NavigationContainer documentTitle='MediciRich'>
	{state.isLoggedIn == 1 && 
            <Stack.Navigator mode="modal"screenOptions={{headerTintColor: 'black'}}>
              <Stack.Screen name="Root" component={BottomTabNavigator} />
            </Stack.Navigator>}
	{state.isLoggedIn == 0 &&
		<SignUpScreen onLogin={(screen) => dispatch({ type: screen })}/>}
	{state.isLoggedIn == undefined && 
	      <RedirectScreen/>}
	{state.isLoggedIn == 2 && 
	      <SignUpErrorScreen/>}
        </NavigationContainer>
      </View>
      </Provider>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: ITEM_WIDTH > 800 ? (ITEM_WIDTH - 800) / 2 : 0,
    justifyContent: "center",
    backgroundColor: '#fff',
  },
});
