import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, Image, ScrollView, Linking, TouchableOpacity, ImageBackground, View, StyleSheet, Dimensions } from 'react-native';
import Constants from 'expo-constants';
import { Card } from 'react-native-elements';
import MasonryList from 'react-native-masonry-list';

const ITEM_WIDTH = Dimensions.get('window').width;


export default function AlumniDetailsScreen({ route, navigation }) {

  const [goods, setGoods] = useState();

 
  const alumni = route.params
  console.log(route.params)

  useEffect(() => {
    axios.get(
      'https://medici-club.com/alumni_lectures/' + alumni.id,
    ).then(res =>{
    setGoods(res.data.sort(({id: previousID}, {id: currentID}) => currentID - previousID));
    console.log(res.data);})
  }, []);
  function renderBadge(level) {
    switch(level) {
      case 1:
      return (
                    <View style = {styles.tag}>
        <Icon name='star'  size = {40} type='font-awesome' color='#cd7f32'/>
                   <Text style = {{fontSize : 25}}>ブロンズ会員限定</Text>
      </View>);
      case 2:
      return(
                    <View style = {styles.tag}>
        <Icon name='star'  size = {40} type='font-awesome' color='silver'/>
                   <Text style = {{fontSize : 25}} >シルバー会員限定</Text>
      </View>);

      case 3:
      return(
                    <View style = {styles.tag}>
        <Icon name='star'  size = {40} type='font-awesome' color='gold'/>
                   <Text style = {{fontSize : 25}} >ゴールド会員限定</Text>
      </View>);
      case 4:
      return(
                    <View style = {styles.tag}>
        <Icon name='star'  size = {40} type='font-awesome' color='#33FFFC'/>
                   <Text style = {{fontSize : 25}} >プラチナ会員限定</Text>
      </View>);
      case 5:
      return(
                    <View style = {styles.tag}>
        <Icon name='star' size = {40} type='font-awesome' color='#EDDA74'/>
                   <Text style = {{fontSize : 25}}>プレミアム会員限定</Text>
      </View>);

    default:
      return ;
  }
}

  return (
    <ScrollView>
    <View style={styles.container}>
      <Image
        source={alumni.uri}
        style={styles.imageStyle}
      />
    <View style={styles.aboutText}>
	  <Text style={styles.title}>{alumni.title}</Text>
	  <Text style={styles.name}>{alumni.name}</Text>
	  <Text style={styles.location}>{alumni.location}</Text>
          <Text>{alumni.description}</Text>
    </View>
      <Image
        source={alumni.qr_code}
        style={styles.qrcodeStyle}
      />
    { alumni.qr_code_url != null && <Text style={{color: 'red', fontSize: 22,textDecorationLine: 'underline'}} onPress={() => Linking.openURL(alumni.qr_code_url)}> 鑑定の館はこちら</Text>}
    </View>
      <View>
	<MasonryList
          columns = {1}
          spacing = {1}
          sorted={true}
          images={goods}
            completeCustomComponent={({ source, style, data}) => {
              return(
                <TouchableOpacity onPress = {() => navigation.navigate('購入内容',{...data, alumni_id : alumni.id })}>
                  <ImageBackground
                    style = {style}
                    source = {source}
                  />
                   <Text style = {styles.priceTag}>{data.price}</Text>
                       {renderBadge(data.level)}
                </TouchableOpacity>
              );
            }}
        />
      </View>

    </ScrollView>
  );
}

const styles = StyleSheet.create({
    title: {
      fontSize: 30,
      fontWeight: 'bold',
      paddingTop: 10,
    },
    name: {
      fontSize: 15,
      paddingTop: 10,
    },
    location: {
      fontSize: 15,
      color: 'gray',
      paddingTop: 3,
      paddingBottom: 7,
    },
    container: {
    flex: 1,
    padding: '7%',
    backgroundColor: 'white',
    alignItems: 'center',
  },
    imageStyle: {
    width: ITEM_WIDTH > 800 ? 800 / 1.5 : ITEM_WIDTH / 1.5,
    height: ITEM_WIDTH > 800 ? 800 / 1.5 : ITEM_WIDTH / 1.5,
    resizeMode: 'cover',
  },
    qrcodeStyle: {
    width: ITEM_WIDTH / 2.2 ,
    height: ITEM_WIDTH / 2.2,
    resizeMode: 'cover',
    marginVertical: ITEM_WIDTH / 10,
  },
    paragraph: {
      margin: 24,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
  },
  aboutText: {
    width: '90%',
},
  tag: {
    flex: 1,
    flexDirection: 'row',
    position: 'absolute',
    top: '1%',
    left: '1%',
    height: 40,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  priceTag: {
          position: 'absolute',
    bottom: '1%',
    left: '1%',
    fontSize: 30,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  indexedStep: {
    color: 'white',
    width: '40px',
    height: '40px',
    fontSize: '12px',
    backgroundColor: 'rgba(0, 116, 217, 1)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
},

});

