import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {users} from '../reducers/users.reducer.js';


export const store = createStore(
    users,
    applyMiddleware(
        thunkMiddleware,
    )
);
