import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TextInput, Platform, StyleSheet, View, Alert, Keyboard, KeyboardAvoidingView, TouchableWithoutFeedback} from "react-native";
import { AwesomeTextInput } from "react-native-awesome-text-input";
import { userActions } from '../actions/user.actions.js';
import { Input, Button } from 'react-native-elements';
import { useForm, Controller } from "react-hook-form";
import { Ionicons } from '@expo/vector-icons';
import axios from 'axios';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import {Icon} from 'react-native-elements';
import Constants from 'expo-constants';

export default function SignUpScreen(props) {

  const { register, setValue, handleSubmit, control, errors } = useForm();
  const queryString = require('query-string');
  const [needsignup,setneedsignup] = useState(false);
  const [loginemail, setloginemail] = useState('');
  const [csrf, setcsrf] = useState('');
  const [otp, setotp] = useState('');
  const [otpsent, setotpsent] = useState(false);
  const dispatch_redux = useDispatch();
  const user = useSelector(state => state.user)
  const [seconds, setSeconds] = useState(0);
  const [failed, setfailed] = useState(false);

  const parsed_query = queryString.parse(location.search)
  var firstname = React.createRef()
  var secondname = React.createRef()
  var email = React.createRef()
  var postcode = React.createRef()
  var firstline = React.createRef()
  var secondline = React.createRef()
  var thirdline = React.createRef()
  var forthline = React.createRef()


  const login = () => {
    fetch("https://medici-club.com/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: "include",
      body: JSON.stringify({username: loginemail, otp: otp}),
    })
    .then((data) => {
      if (data.status == 400) {
	setfailed(true);
      }
      if (data.status == 200) {
      props.onLogin('GO_TO_HOME');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
  const sendOTP = () => {
    setfailed(false);
    fetch("https://medici-club.com/sendotp/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: "include",
      body: JSON.stringify({username: loginemail}),
    })
    .then((data) => {
      if (data.status == 400) {
	setneedsignup(true);
      } else {
      setotpsent(true)
      }
    })
    .catch((err) => {
      console.log(err);
    });

  }
  const getCSRF = () => {
    fetch("https://medici-club.com/csrf/", {
      credentials: "include",
    })
    .then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      //save token to storage 
      dispatch_redux(userActions.csrf(csrfToken));
      setcsrf(csrfToken);
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getSession = () => {
    fetch("https://medici-club.com/session/", {
      credentials: "include",
    })
    .then((res) => res.json())
    .then((data) => { 
      console.log(data);
      if (data.isAuthenticated) {
        props.onLogin('GO_TO_HOME');
      } else {
        getCSRF();
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

   useEffect(() => {
     getSession();
   }, []);

  const onSubmit = async(data) => {
    if(data.secondname == "" || data.firstname == "" || data.email == ""){
      console.log(data)
      return
    }  
    try {
	const userData = {
	  "username" : data.firstname + data.secondname + '-' + data.email,
	  "first_name" : data.firstname,
	  "second_name" : data.secondname,
	  "email" : data.email,
	  "UID" : parsed_query.uid,
	  "postcode" : data.postcode,
	  "firstline" : data.firstline,
	  "secondline" : data.secondline,
	  "thirdline" :data.thirdline,
	  "forthline" : data.forthline,
	  "phone" : parsed_query.phone,
	  "parent_phone" : parsed_query.parent_phone,
	  "parent_email" : parsed_query.ref == null ? null : Buffer(parsed_query.ref, 'base64').toString('ascii'),
	}
        const api = 'https://medici-club.com/users/';
 	axios.post(api, userData,{ headers: {'Content-Type': 'application/json'}}).then(res=> {props.onLogin('SHOW_REDIRECT')})
	 .catch(error => {
    if (error.response && error.response.status === 400) {
	props.onLogin('SHOW_SIGNUPERROR');
      // Handle the 400 status here
      // Optionally, you can invoke a function to handle this specific error
      // For example:
    } else {
      // Handle other errors
      console.error('An error occurred:', error.message);
    }
    })
    } catch (E) {
      console.log(E);
    }
  };

  const onChange = arg => {
    return {
      value: arg.nativeEvent.text,
    };
  };
  if (needsignup) return (
      <View style={styles.container}>
      <Text style={styles.title}>サインアップ</Text>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             label="姓" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => firstname.focus()}
            />
          )}
          name="secondname"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {firstname = ref;}}
             label="名" 
             onBlur={onBlur}
             returnKeyType="next"
             onChangeText={value => onChange(value)}
             value={value}
             onSubmitEditing={() => email.focus()}
            />
          )}
          name="firstname"
        />
        </View>
	<View style={styles.inputContainer}>
        <Controller
          control={control}
          defaultValue= {parsed_query.email}
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {email = ref;}}
             label="メールアドレス"
             onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => postcode.focus()}
            />
          )}
          name="email"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {postcode = ref;}}
             label="郵便番号" 
             keyboardType='numeric'
	     onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => firstline.focus()}
            />
          )}
          name="postcode"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {firstline = ref;}}
             label="都道府県" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => secondline.focus()}
            />
          )}
          name="firstline"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {secondline = ref;}}
             label="市区町村" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => thirdline.focus()}
            />
          )}
          name="secondline"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {thirdline = ref;}}
             label="番地" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => forthline.focus()}
            />
          )}
          name="thirdline"
        />
        </View>
        <View style={styles.inputContainer}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {forthline = ref;}}
             label="建物名" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
            />
          )}
          name="forthline"
        />
        </View>
      <View style={styles.button}>
        <Button buttonStyle={{ backgroundColor : "red"}} color="red" onPress = {handleSubmit(onSubmit)} title={'サインアップ'}/>
      </View>
      </View>  
   );
   if (!needsignup) return(

      <View style={styles.container}>
        <Text style={styles.title}>メールログイン</Text>
        <View style={styles.button}>
        <Text style={styles.subTitle}>メールアドレス</Text>
	<TextInput
	　style={styles.input}
	  keyboardType = 'email-address'
	  onChangeText={setloginemail}
          value={loginemail}
        />
        <Text style={styles.subTitle}>認証コード</Text>
	<TextInput
	　style={styles.input}
	  keyboardType = 'numeric'
	  onChangeText={setotp}
          value={otp}
        />
        </View>
        <View style={styles.button}>
	   {otpsent && failed ?
		   <Button buttonStyle={{ backgroundColor : "red"}} color="red" disabled={otp == ''} onPress = {login} title='認証コードが間違っています。もう一度最初からやってみてください'/>
		   :
		   <Button buttonStyle={{ backgroundColor : "red"}} color="red" disabled={otp == ''} onPress = {login} title='ログイン'/>
	   }
        </View>
        <View style={styles.button}>
         {otpsent ? 
		 <Button titleStyle={{ color: 'red' }} disabled={loginemail == ''} onPress = {sendOTP} type='clear' title="認証コードを再発行(60秒有効)" />
		 :
		 <Button buttonStyle={{ backgroundColor : "red"}} color="red" disabled={loginemail == ''} onPress = {sendOTP} title="認証コードを発行(60秒有効)"/>
	 }
        </View>
      </View>
   )
};

const styles = StyleSheet.create({
　input: {
    height: 40,
    margin: 3,
    borderWidth: 1,
  },
  label: {
    margin: 20,
    fontSize: 20,
    fontWeight: 'normal',
    marginLeft: 0,
  },
  subTitle: {
    color: 'gray',
    margin: 3,
  },
  title: {
    marginTop: 30,
    fontSize: 30,
    color: 'gray',
    fontWeight: 'bold',
  },
  button: {
    margin: 20,
    borderRadius: 4,
  },
  container: {
    alignItems: 'center',
    margin: 30,
  },
   inputContainer: {
    marginTop: 20,
    alignSelf: 'stretch',
   },
});
