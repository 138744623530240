import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, View, StyleSheet, ImageBackground, TouchableOpacity, ScrollView } from 'react-native';
import { Constants } from 'expo';
import { Icon } from 'react-native-elements';
import MasonryList from 'react-native-masonry-list';


export default function MembershipScreen({ route, navigation }) {

  const [goods, setGoods] = useState();

  useEffect(() => {
    axios.get(
      'https://medici-club.com/membership',
    ).then(res =>{
    setGoods(res.data.sort(({id: previousID}, {id: currentID}) => currentID - previousID));
    console.log(res.data);})
  }, []);
  
  return (
      <View style={styles.container}>
        <MasonryList
          columns = {1}
	  spacing = {1}
          sorted={true}
          images={goods}
	    completeCustomComponent={({ source, style, data}) => {
              return(
                <TouchableOpacity onPress = {() => navigation.navigate('会員情報',data)}>
                  <ImageBackground
                    style = {style}
                    source = {source}
                  />
		   <Text style = {styles.priceTag}>{data.price} USDT</Text>
                </TouchableOpacity>
              );
            }}
        />
      </View>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  tag: {
    flex: 1,
    flexDirection: 'row',
    position: 'absolute',
    top: '1%',
    left: '1%',
    height: 40,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  priceTag: {
	  position: 'absolute',
    bottom: '1%',
    right: '1%',
    fontSize: 30,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
    aboutText: {
    padding: 20,
  },
    imageStyle: {
    width: '100%',
    resizeMode: 'cover',
  },
    paragraph: {
      margin: 24,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
  },
  indexedStep: {
    color: 'white',
    width: '40px',
    height: '40px',
    fontSize: '12px',
    backgroundColor: 'rgba(0, 116, 217, 1)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
},
});

