import * as React from 'react';
import { ScrollView, StyleSheet, Dimensions, StatusBar } from 'react-native';
import { TabBar, TabView, SceneMap } from 'react-native-tab-view';
import { ListItem } from 'react-native-elements';


export default function PointDetails({ route, navigation }) {
const renderTabBar = props => (
  <TabBar
    {...props}
    indicatorStyle={{ backgroundColor: 'gold' }}
    style={{ backgroundColor: '#989898' }}
  />
);

const list2 = [
  {
    timestamp: '2021/6/23',
    amount: '9000P'
  },
]
const list = [
  {
    name: 'ポイント消費',
    timestamp: '2020/11/23',
    amount: '-1000P'
  },
  {
    name: 'ポイント購入',
    timestamp: '2020/10/23',
    amount: '10000P'
  },
]


const FirstRoute = () => (
<ScrollView>
  {
    route.params.point_list.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
	 <ListItem
	key={i}
	title={l.amount}
	rightTitle={l.date}
      />
    ))
  }
</ScrollView>
);

const SecondRoute = () => (

<ScrollView>
  {
    route.params.point_history.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
	 <ListItem
	key={i}
        title={l.action}
	subtitle={l.date}
	rightTitle={l.amount}
      />
    ))
  }


</ScrollView>

);

const initialLayout = { width: Dimensions.get('window').width };

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'first', title: 'ポイント期限' },
    { key: 'second', title: 'ポイント履歴' },
  ]);

  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
  });

  return (
    <TabView
      navigationState={{ index, routes }}
      renderTabBar={renderTabBar}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={initialLayout}
      style={styles.container}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: StatusBar.currentHeight,
  },
  scene: {
    flex: 1,
  },
});

