import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import { ProgressBar,Step } from "react-step-progress-bar";
import { Icon } from 'react-native-elements'


export default function LevelText({style, level}) {

  function switchText(l) {
    switch(l) {
      case 1:
      return ('ブロンズ会員限定');
      case 2:
      return ('シルバー会員限定');
      case 3:
      return ('ゴールド会員限定');
      case 4:
      return ('プラチナ会員限定');
      case 5:
      return ('プレミアム会員限定');
    default:
      return ;
  }
}


return(
    <Text style={style}>{switchText(level)}</Text>
);

}
