import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, View, StyleSheet, ImageBackground, FlatList, Dimensions, TouchableOpacity } from 'react-native';
import Constants from 'expo-constants';
import { Button, Card } from 'react-native-elements';

const ITEM_WIDTH = Dimensions.get('window').width;

export default function  CertificateScreen({ route, navigation }) {


  const [certificates, setCertificates] = useState();

  useEffect(() => {
    axios.get(
      'https://medici-club.com/certificates/',
    ).then(res =>{
            console.log(res.data);
    setCertificates(res.data.sort(({id: previousID}, {id: currentID}) => currentID - previousID));})
  }, []);


    return (
      <View style={styles.container}>
        <FlatList 
        data={certificates}
        keyExtractor={(item, title) => title.toString()}
        numColumns={1}

        renderItem={({item}) => (
          <View>
            <Card>
              <View style={{flexDirection: 'column', alignItems: 'center'}}>
                <ImageBackground
                  source={'https://medici-club.com/media/images/defaults/certificate.jpg'}
                  style={styles.imageStyle}
                />
		<View style={styles.certificate}>
                <Text style={styles.titleStyle}>{item.title}</Text>
                <Text style={styles.detailsStyle}>{item.description}</Text>
              </View>
              </View>
        </Card>

          </View>
        )}
        />
      </View>
    );
  }

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: Constants.statusBarHeight,
    backgroundColor: '#ecf0f1',
  },
  imageStyle: {
    width: ITEM_WIDTH / 1.2,
    height: ITEM_WIDTH / 1.45,
  },
  certificate: {
    position: 'absolute',
    top: '10%',
    right: '10%',
    width: '60%',
    alignItems: 'center',
  },
   titleStyle: {
     marginTop : 5,
     fontSize : 20,
     fontWeight: "bold",
   },
   detailsStyle: {
     marginTop : 15,
     fontSize : 14,
   }
});
