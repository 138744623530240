import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Image, View, ScrollView, Linking, TextInput, StyleSheet, Dimensions, CheckBox } from 'react-native';
import Modal from "modal-enhanced-react-native-web";
import { Icon , Button, Overlay } from 'react-native-elements';
import LevelText from '../components/levelText';
import { useForm, Controller } from "react-hook-form";
import { userActions } from '../actions/user.actions.js';
import AwesomeAlert from 'react-native-awesome-alerts';
import axios from 'axios';
import { store } from '../helpers/store.js';
import { Input } from 'react-native-elements';

const ITEM_WIDTH = Dimensions.get('window').width;


export default function PurchaseDetailsScreen({ route, navigation }) {
  
  const good = route.params
  const dispatch_redux = useDispatch();
  const user = useSelector(state => state.user)
  const [modalVisible, setModalVisible] = useState(false);
  const [pointmodalVisible, setPointModalVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [isChecked, setCheck] = useState(false);
  const [amount, setAmount] = useState(1);
  const alumni_id = good.alumni_id;

  const { register, setValue, handleSubmit, control, errors } = useForm();
  var name = React.createRef()
  var postcode = React.createRef()
  var phone = React.createRef()
  var firstline = React.createRef()
  var secondline = React.createRef()
  const onChange = arg => {
    return {
      value: arg.nativeEvent.text,
    };
  };    
  const onSubmit = async(data) => {
    if(isChecked && ( data.name == "" || data.postcode == "" || data.phone == "" || data.firstline == "" || data.secondline == "")){
      console.log('Please fill all form')
      return
    }
    if (isChecked){
    handlePurchase(data.name + '\n' + data.postcode + '\n' + data.phone + '\n' + data.firstline + '\n' + data.secondline);
    } else {
    handlePurchase('');
    }
  }

  function handlePurchase(extra_text) {
    const queryString = require('query-string');
    const parsed_query = queryString.parse(location.search);
    const api = 'https://medici-club.com/purchase/';
//    const api = 'http://medici-club.com:8000/purchase/';
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'id' : good.id, 'alumni_id' : alumni_id, 'description' : extra_text}}).
      then(res => {
	setModalVisible(false);
	setAlertVisible(true);
	dispatch_redux(userActions.updatePoint(res.data))
        handleSendEmail();
      }).catch(function (error) {
        console.log(error);
      });

  }

  function handleSendEmail(UID, access_token) {
    const queryString = require('query-string');
    const parsed_query = queryString.parse(location.search);
    const api = 'https://medici-club.com/purchasesendemail/';
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'id' : good.id}}).catch(function (error) {
        console.log(error);
      });
  }


  function handleLink() {
    Linking.openURL(good.link);
    setAlertVisible(true);
  }

  function handlePointPurchase(UID, access_token) {
    const queryString = require('query-string');
    const parsed_query = queryString.parse(location.search);
    const api = 'https://medici-club.com/pointpurchase';
    if(amount != 0){
    axios.post(api,'', { params: { 'access_token' : 'dummytoken', 'UID' : user.email, 'amount' : amount}}).
      then(res => {
      if (res.status == 200) {
	setPointModalVisible(false);
	dispatch_redux(userActions.updatePoint(+user.point_balance + +amount))
	dispatch_redux(userActions.updateToken(res.data))
	console.log(res.data)
      }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  return (
    <ScrollView>
    <View style={styles.container}>
      <Image
        source = {good.uri}
        style = {styles.imageStyle}
        resizeMode = 'contain'
      />
    
    <View>
      <Text style={styles.title}>{good.title}</Text>
    </View>
    <View style={styles.subTitle}>
	   <LevelText style= {styles.tagText} level={good.level}/>
          <Text style={styles.price}>{good.price}P({good.price_usdt}USDT相当)</Text>
    </View>
    <View style={styles.subTitle}>
	   <LevelText style= {styles.tagText} level={0}/>
          <Text style={styles.price}>残り{good.remaininginventory}/{good.inventory}点</Text>
    </View>
    <View style={styles.buttonWrapper}>
      {user.level >= good.level && good.remaininginventory > 0 && (good.link != "" ? <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} onPress = {() => handleLink()} title='日付を選択する'/> : <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} onPress = {() => setModalVisible(true)} title='商品を購入する'/>)}

      {good.remaininginventory <= 0 && <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} disabled = {true} onPress = {() => setModalVisible(true)} title='現在在庫切れです。'/>}
      {user.level < good.level && good.remaininginventory > 0 && <Button buttonStyle={[styles.mainButton]} titleStyle={styles.mainButtonText} disabled = {true} onPress = {() => setModalVisible(true)} title='会員ランクを満たしていません'/>}
	  </View>
	  {good.stamps.length > 0 && <View style={styles.bonusWrapper}>
	  <Text>還元スタンプ:</Text>
	   {good.stamps.map(stamp => <Image source={stamp} style={styles.stamp}/> )}
	  </View>}
     {good.bonuspoints > 0 && <View style={styles.bonusWrapper}>
	   <Text>還元ポイント: {good.bonuspoints}P</Text>
	  </View>}
    <View style={styles.aboutText}>
      <Text>{good.description}</Text>
    </View>
    </View>
	<Modal
          isVisible = {modalVisible}
          style={styles.bottomModal}	  
        >
	      <View style={styles.modalContent}>
	<View style={styles.paymentListItem}>
      <Text style={styles.paymentTitleText}>{good.title}</Text>
      <Icon
        name='close'
        type='evillcons'
	  onPress = {() => setModalVisible(false)}/>
        </View> 
        <View style={styles.paymentListItem}> 
        <Text>{'所持ポイント:'}</Text> 
	<Text>{user.point_balance + 'P'}</Text>
    </View>
    <View style={styles.paymentCheckBox}>
        <CheckBox
          value={isChecked}
          onValueChange={setCheck}
          style={styles.checkbox}
        />
        <Text style={styles.label}>送付先を入力する</Text>
    </View>
    { isChecked && <View>
        <View style={styles.paymentListItem}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             label="お名前" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => postcode.focus()}
            />
          )}
          name="name"
        />
        </View>
        <View style={styles.paymentListItem}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {postcode = ref;}}
             label="郵便番号" 
             keyboardType='numeric'
	     onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => phone.focus()}
            />
          )}
          name="postcode"
        />
        </View>
        <View style={styles.paymentListItem}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {postcode = ref;}}
             label="電話番号" 
             keyboardType='numeric'
	     onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => firstline.focus()}
            />
          )}
          name="phone"
        />
        </View>
        <View style={styles.paymentListItem}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {firstline = ref;}}
             label="都道府県" 
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => secondline.focus()}
            />
          )}
          name="firstline"
        />
        </View>
        <View style={styles.paymentListItem}>
        <Controller
          control={control}
	  defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
             ref={ref => {secondline = ref;}}
             label="以降の住所"
              onBlur={onBlur}
              returnKeyType="next"
              onChangeText={value => onChange(value)}
              value={value}
              onSubmitEditing={() => thirdline.focus()}
            />
          )}
          name="secondline"
        />
        </View>

        </View>}
	  

    <View style={styles.paymentListItem}>
	  {user.point_balance >= good.price && <Button buttonStyle={{backgroundColor : "red"}} onPress = {handleSubmit(onSubmit)} color="red" title={'ポイントで支払う'}/>}
	  {user.point_balance < good.price && <Button buttonStyle={{backgroundColor : "red"}} disabled = {true} color="red" title={'ポイント不足'}/>}
          <Button titleStyle={{color : "red"}} color="red" type="clear" onPress = {() => setPointModalVisible(true)} title={'ポイントを購入'}/>
    </View>
    </View>
	  </Modal>
	<Modal
          isVisible = {pointmodalVisible}
          style={styles.bottomModal}	  
        >
	      <View style={styles.modalContent}>
	<View style={styles.paymentListItem}>
      <Text style={styles.paymentTitleText}>{'ポイントを購入する'}</Text>
      <Icon
        name='close'
        type='evillcons'
	  onPress = {() => setPointModalVisible(false)}/>
        </View> 
        <View style={styles.paymentListItem}> 
        <Text>{'所持ポイント:'}</Text> 
	<Text>{user.point_balance + 'P'}</Text>
        </View>
        <View style={styles.paymentListItem}> 
        <Text>{'購入ポイント:' }</Text>
	<TextInput
	  style = {styles.textInput}
	  keyboardType = 'numeric'
	  onChangeText={text => setAmount(text)}
          value={amount}
      />
    </View>
	<View style={styles.paymentListItem}>
          <Button buttonStyle={{backgroundColor : "red", minWidth : 150 }} title={'購入に進む'} onPress = {() => handlePointPurchase()} />
    </View>
    </View>
	  </Modal>
   	<AwesomeAlert
          show={alertVisible}
          showProgress={false}
          title="お知らせ"
          message={good.link == '' ? "購入が完了しました" : "メールを送信しました"}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          confirmText="閉じる"
          confirmButtonColor="red"
          onConfirmPressed={() => setAlertVisible(false)}
        />
	  </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',

  },
    bonusWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: '5%',
    paddingTop: '2%'
  },
    subTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: '5%',
    paddingVertical: '1%'
    
  },
    title: {
     marginTop : 10,
     fontWeight: "bold",
     fontSize : 25,
     paddingLeft: '5%',
     paddingRight: '5%'
   },
    aboutText: {
    padding: 20, 
  }, 
    paymentTitleText : {
     fontWeight: "bold",
     fontSize: 20,
  },
    imageStyle: {
    width: ITEM_WIDTH > 800 ? 800 : ITEM_WIDTH,
    height: ITEM_WIDTH > 800 ? 800 : ITEM_WIDTH,
  },
    paragraph: {
      margin: 24,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
  },
    mainButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  mainButton: {
    margin: 5,
    height: 40,
    width: ITEM_WIDTH > 800 ? 800 / 1.2 : ITEM_WIDTH / 1.2,
    borderRadius: 5,
    backgroundColor: 'red'
  },
   tagText: {
     fontSize: 15,
  },
   price:{
     color: 'red',
     fontSize: 23,
   },
   stamp:{
     height: 25,
     width: 25,
     marginLeft: 7,
   },
  buttonWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 20,
    fontColor: 'gray',
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0
  },
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)"
  },
  paymentTitle: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    },
  paymentCheckBox: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginBottom: 10,
    justifyContent: 'start',
  },
  label: {
    marginLeft: 8,
  },
  paymentListItem: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginBottom: 15,
    justifyContent: 'space-between',
  },
  textInput: {
    borderWidth: 1,
  },
});

