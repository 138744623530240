import * as React from 'react';
import { ScrollView, StyleSheet, Dimensions, StatusBar } from 'react-native';
import { TabBar, TabView, SceneMap } from 'react-native-tab-view';
import { ListItem } from 'react-native-elements';


export default function PointDetails({ route, navigation }) {
const renderTabBar = props => (
  <TabBar
    {...props}
    indicatorStyle={{ backgroundColor: 'gold' }}
    style={{ backgroundColor: '#989898' }}
  />
);



const FirstRoute = () => (
<ScrollView>
  {
    route.params.affiliate_list.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
	 <ListItem
	key={i}
	title={l.name}
	rightTitle={l.date}
      />
    ))
  }
</ScrollView>
);

const SecondRoute = () => (

<ScrollView>
  {
    route.params.affiliate_history.filter(function(item) { if (item == null) {return false;} return true;}).map((l, i) => (
	 <ListItem
	key={i}
        title={l.name}
	subtitle={l.date}
	rightTitle={l.amount + ' USDT'}
      />
    ))
  }


</ScrollView>

);

const initialLayout = { width: Dimensions.get('window').width };

  console.log(route.params);
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'first', title: 'アフィリエイト一覧' },
    { key: 'second', title: 'アフィリエイト履歴' },
  ]);

  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
  });

  return (
    <TabView
      navigationState={{ index, routes }}
      renderTabBar={renderTabBar}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={initialLayout}
      style={styles.container}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: StatusBar.currentHeight,
  },
  scene: {
    flex: 1,
  },
});

